.confirmLogo {
  max-width: 140px;
  max-height: 140px;
  display: block;
  margin: 10px auto;
}
.loader {
  display: block;
  margin: 45px auto 40px;
  width: 75px;
  height: 75px;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.mainMenuBtn {
  position: absolute;
  bottom: 0;
  margin: 30px auto;
  left: 0;
}