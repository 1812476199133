@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

.merchantType {
  position: fixed;
  width: 330px;
  .Toastify__toast--success {
    background: white;
    color: black;
    font-weight: bold;
    border: 1px solid #c5fac9;
    background-image: url(./assets/images/success.png);
    background-position: left;
    background-repeat: no-repeat;
    background-position-x: 6px;
    border-radius: 10px;
    .Toastify__toast-body {
      width: 100%;
      max-width: 257px;
      font-size: 12px;
      padding: 6px 6px 6px 35px;
    }
    .Toastify__close-button {
      color: black;
      border: 1px solid #c5fac9;
      border-radius: 15px;
      background: #c5fac9;
      width: 28px;
      height: 28px;
      align-self: center;
    }
  }
  .Toastify__toast--error {
    background: white;
    color: black;
    font-weight: bold;
    border: 1px solid #ffd5d5;
    background-image: url(./assets/images/failure.png);
    background-position: left;
    background-repeat: no-repeat;
    background-position-x: 6px;
    border-radius: 10px;
    .Toastify__toast-body {
      width: 100%;
      max-width: 257px;
      font-size: 12px;
      padding: 6px 6px 6px 35px;
    }
    .Toastify__close-button {
      color: #ab5252;
      border: 1px solid #ffd5d5;
      border-radius: 50%;
      background: #ffd5d5;
      width: 28px;
      height: 28px;
      align-self: center;

      svg {
        width: 16px;
        opacity: 1;
      }
    }
  }
}
