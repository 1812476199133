// Here you can add other styles
  
  @media only screen and (min-width: 768px) {
    .label {
        padding-right: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .label{
        padding-right: 10px;
    }
    .dateSection {
      .labelDate {
        display: flex;
        justify-content: space-between;
      }
      .button {
        justify-content: center;
      }
    }
  }