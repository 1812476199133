.headerBlock {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px #E5E5E5;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 9;
  top: 0;

  .leftArrow, .logo {
    width: 20px;
    max-height: 40px;
    min-height: 30px;
    margin: auto 5px;
    cursor: pointer;
    float: left;
  }

}