.modal-checkout .intl-tel-input .selected-flag {
  z-index: 1;
  position: relative;
  margin-left: 10px;
  border-right: 1px solid rgba(215, 215, 215, 0.8);
  height: 100%;
}
.number-input:focus {
  border-color: rgba(177, 177, 177, 1) !important;
}
.modal-checkout .intl-tel-input.allow-dropdown input,
.modal-checkout .intl-tel-input.allow-dropdown input[type='tel'] {
  cursor: text;
  border-radius: 50px;
  height: 100%;
  border: 1px solid rgba(215, 215, 215, 0.8);
  padding-left: 60px;
  color: rgb(111, 111, 111);
  font-size: 20px;
}
.modal-checkout
  .intl-tel-input.allow-dropdown
  input::-webkit-input-placeholder {
  color: rgba(111, 111, 111, 0.5);
  font-size: 17px;
  letter-spacing: 1px;
  padding-left: 5px;
}

// .modal-checkout .intl-tel-input.allow-dropdown input[type="tel"]:focus {
//   border-color: red;
// }
.phonenumber-text-container {
  height: 12%;
  width: 50%;
  border-radius: 50px;
  background-color: rgb(248, 247, 252);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  left: 50%;
  transform: translateX(-80%);
}

.phonenumber-text {
  height: 70%;
  width: 90%;
  border-radius: 50px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 5px 3px rgba(215, 215, 215, 0.9);
  font-weight: 500;
  color: rgb(111, 111, 111);
}

.checkout-container {
  position: fixed;
  width: 100%;
  height: 130vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
}

.checkout-div {
  position: fixed;
  width: 100%;
  max-width: 500px;
  background: white;
  border-radius: 20px 20px 0 0;
  // overflow-y: auto;
  min-height: 60%;
  // height: 70%;
  max-height: 90%;
  // overflow-x: hidden;
  padding-bottom: 5px;
  bottom: 0;
}

.modal-checkout,
.modal-verify {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // align-items: center;
  height: 60%;
  margin: 10px 0;
}

.checkout-modal {
  //transform: translateX(0) translateY(15%) translateZ(0);
  height: 400px;
}
.primaryModal {
  display: flex;
}
.input-font-size {
  font-size: 16px !important;
}
.modal-title {
  margin-left: 40px;
  border: none;
}

.modal-checkout h4 {
  text-align: center;
  color: #2eb85c;
  margin-top: 1rem;
}
.modal-checkout p {
  text-align: center;
  justify-self: flex-end;
}
.modal-verify p {
  text-align: center;
  justify-self: flex-end;
}
.modal-verify div input {
  margin: 5px;
}
.modal-verify h3 {
  text-align: center;
  //color: #2eb85c;
  margin-top: 1rem;
}
.modal-checkout input {
  width: 80%;
  border: none;
  border-bottom: 1px solid #000000;
  font-size: 20px;
  margin-top: 30px;
  justify-content: center;
  margin-left: 26px;
}
.modal-checkout input:focus {
  outline: rebeccapurple;
}

.otp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  border-radius: 50px;
  height: 45px;
  margin-top: 30px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.verify-button {
  width: 80%;
  height: 45px;
  margin-bottom: 20px;
}
.verify-button button {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 50%;
  transform: translateX(-37.5%);
  border: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.change-number {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.change-number button {
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 50px;
  margin-left: 6px;
  padding: 2px;
}

.change-number button svg {
  padding-bottom: 2px;
}

.otp-text span {
  display: flex;
  justify-content: center;
}
.cancel-button {
  // display: flex;
  // position: absolute;
  // top: 1%;
  // right: 7px;
  // cursor: pointer;
  border: 1px solid rgba(221, 221, 221, 0.8);
  color: rgba(111, 111, 111, 0.9);
  width: 30px;
  height: 30px;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  line-height: 1.42857;
  position: absolute;
  top: -15px;
  right: 5px;
}
.number-input {
  width: 100%;
}
.otp-input {
  padding-left: 16px;
  width: 80%;
  height: 100%;
  border: 1px solid rgba(215, 215, 215, 0.8);
  font-size: 20px;
  border-radius: 50px;
}
.otp-input:focus {
  outline: rgb(111, 111, 111);
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 50px;
  transition: none;
}
// .otp-input::-webkit-input-placeholder {
//   text-align: center;
// }
.resend-button {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin: 7px;
}
.resend-button button {
  border-radius: 50px;
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: rgb(111, 111, 111);
  font-weight: 500;
}

@media only screen and (max-width: 300px) {
  .otp-button {
    margin-top: 1rem;
  }
  .modal-checkout h4 {
    margin-top: 0.5rem;
  }
}
