.spanCouponCode {
  border-radius: 5px;
  // padding: 5px 10px;
  .offer_code {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto;
  }
}
.couponDesc {
  font-size: 12px;
  font-style: italic;
}
.offerBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .card-text {
    width: 100%;
    max-width: 100px;
    max-height: 60px;
  }
  .couponDesc {
    max-width: 155px;
    padding: 0px 10px 0px 10px;
  }
  .leftContent {
    border: 1px dotted black;
    border-radius: 13px;
    padding: 0px 5px 0px 5px;
    text-align: center;
  }
}
.removeButton {
  background: #cd3233;
  text-align: center;
  color: #ffffff !important;
  font-size: 16px;
  padding: 5px 15px 5px 15px;
  width: 100%;
  max-width: 110px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  transition: all 0.5s ease;
}
.coupon-code {
  color: #444444;
  background: linear-gradient(
      315deg,
      rgba(0, 0, 0, 0.06) 10%,
      rgba(0, 0, 0, 0) 15%,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 0, 0.06) 40%,
      rgba(0, 0, 0, 0.06) 60%,
      rgba(0, 0, 0, 0) 65%,
      rgba(0, 0, 0, 0) 85%,
      rgba(0, 0, 0, 0.06) 90%
    )
    repeat scroll 0 0/6px 6px rgba(0, 0, 0, 0);
  text-align: right;
  padding: 10px 12px;
  font-size: 20px;
  border: 2px dotted #cd3233;
  position: relative;
  :hover {
    text-decoration: none;
  }
  .code-text {
    text-transform: uppercase;
  }
  .get-code {
    position: absolute;
    left: -2px;
    top: -2px;
    background: #cd3233;
    color: #ffffff;
    font-size: 16px;
    padding: 14px 15px 9px 14px;
    border-top-right-radius: 30px 26px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    transition: all 0.5s ease;
  }
}
.Disabled {
  color: #c5bbbb !important;
}
.coupon-code .get-code:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid #991717;
  position: absolute;
  right: 0px;
  top: 0;
}
.spinner {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 50px;
}
.rowPadding {
  overflow: auto;
  padding-bottom: 100px;
  max-width: 500px;
  height: 65vh;
}
.ch-no-offers {
  padding: 10px;
  margin: 10px;
  background: white;
  cursor: default;
  font-weight: bold;
  color: red;
}
.countCoupon {
  color: green;
  padding: 0px 10px;
}
.paddingBox {
  padding: 5px 10px;
}
.offerIdField {
  border: none;
  border-bottom: 1px solid black;
  width: 75%;
  border-radius: 0px;
  font-size: 16px;
}
.offerIdField:focus {
  outline: none;
  box-shadow: none;
  border-radius: 0px;
  border-bottom: 1px solid black;
  font-size: 16px;
}
.mannualOfferCode {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  padding: 0.8rem;
  // cursor: pointer;
  // position: fixed;
}
.mannualOfferCode h2 {
  // border: 1px solid black;
  text-align: center;
  margin-top: 2px;
}
.mannualOfferCode input {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}
.paymentText {
  padding-right: 3px;
  font-size: 14px;
  color: green;
}
.offers {
  padding: 0.8rem;
}
.offers-coupons-div {
  position: absolute;
  width: 100%;
  max-width: 500px;
  background: white;
  border-radius: 20px 20px 0 0;
  overflow: auto;
  max-height: 90vh;
  height: 90vh;
  padding: 0.8rem;
  overflow-y: scroll;
}
.offers-div {
  position: absolute;
  width: 100%;
  max-width: 500px;
  background: white;
  border-radius: 20px 20px 0 0;
  overflow: auto;
  overflow-y: scroll;
  max-height: 90vh;
  height: 90vh;
}

.coupon-cancel {
  // justify-content: right;
  // align-items: right;
  // display: flex;
  // text-align: right;
  // float: right;

  // padding: 5px;
  // border: 1px solid;
  // border-radius: 50%;
  // cursor: pointer;
  // position: absolute;
  // right: 2%;
  // top: 1%;

  width: 30px;
  border: 1px solid rgba(221, 221, 221, 0.8);
  color: rgba(111, 111, 111, 0.9);
  height: 30px;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
  line-height: 1.42857;
  position: absolute;
  top: -15px;
  right: 10px;
}

.coupon-container {
  position: fixed;
  width: 100%;
  height: 106vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
}

.coupon-div {
  position: absolute;
  width: 100%;
  max-width: 500px;
  background: white;
  border-radius: 20px 20px 0 0;
  max-height: 90vh;
  height: 90vh;
}

.buttonStyle {
  text-align: center;
  background: #cd3233;
  color: #ffffff !important;
  font-size: 16px;
  padding: 5px 15px 5px 15px;
  width: 100%;
  max-width: 110px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  transition: all 0.5s ease;
}
@media screen and (max-width: 400px) {
  .buttonResponsive {
    display: flex;
    justify-content: center;
  }
  .offerBody {
    display: grid;
    justify-content: center;
    text-align: center;
    .card-text {
      max-width: 260px;
    }
    .couponDesc {
      padding: 15px 0px 15px 0px;
      max-width: 267px;
    }
  }
  .buttonStyle {
    padding-top: 5px;
  }
}
